<template>
    <div class="king-kong">
        <h4 class="title">{{index | sort}}</h4>
        <div class="option flex">
            <span class="label">导航名称：</span>
            <el-input v-model="edit.activity_name" placeholder="建议2-4字，最多5字" style="width: 294px;"></el-input>
        </div>
        <div class="option flex">
            <span class="label">跳转类型：</span>
            <el-select v-model="edit.jump_type" placeholder="请选择" style="width: 294px;" disabled>
                <el-option
                        v-for="item in jumpOptions"
                        :key="item.type"
                        :label="item.name"
                        :value="item.type">
                </el-option>
            </el-select>
        </div>
        <div class="option flex">
            <span class="label">跳转类型：</span>
            <el-cascader :options="categoryOptions" v-model="edit.external_params" style="width: 294px;"/>
        </div>
        <div class="upload">
            <span class="label">上传图片</span>
            <slot></slot>
        </div>
        <span class="tips">建议图片尺寸为138x138像素，且大小 &lt;=50kb，支持jpg、png格式</span>
    </div>
</template>

<script>
    import {mapState} from 'vuex'

    export default {
        name: 'KingKong',
        props: {
            index: Number,
            edit: {
                type: Object,
                default: () => {
                    return {
                        activity_name: '',
                        jump_type: 4,
                        external_params: [],
                        position_type: 2
                    }
                }
            }
        },
        computed: mapState([
            'jumpOptions',
            'categoryOptions'
        ]),
        filters: {
            sort(index) {
                const indexs = ['排序一', '排序二', '排序三', '排序四', '排序五', '排序六', '排序七', '排序八']
                return indexs[index]
            }
        }
    }
</script>

<style scoped lang="scss">
    .king-kong {
        padding: 32px 0;
        background-color: #fff;
        border-top: 1px solid #E8E8E8;
        box-sizing: border-box;

        .title {
            margin: 0 0 16px 0;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
        }

        .label {
            color: rgba(0, 0, 0, 0.65);
        }

        .tips {
            color: rgba(0, 0, 0, 0.45);
        }

        .option {
            margin-bottom: 24px;
            align-items: center;
        }

        .upload {
            margin-bottom: 8px;
        }
    }
</style>
