<template>
    <div class="page">
        <PageTitle title="金刚区部署"></PageTitle>
        <div class="content flex">
            <div class="edit">
                <h3 class="title">金刚区8个导航位置部署</h3>
                <img src="https://image.topboom0912.com/admin_static_img/demo/index_kingkong_demo.png" alt="demo"
                     width="415px">
                <div class="tips">金刚区根据产品界面布局统一规范，由设计师按要求整套制定，<span style="color: #1890FF;">切勿随意更换</span></div>
                <Kingkong v-for="(item, index) in edit" :key="item.id" :edit="item" :index="index">
                    <div class="upload" @click="chooseIndex(index)">
                        <Upload :id="'kingkong-' + index" src="activity" @success="onUpload">
                            <div class="logo" v-if="item.imgurl">
                                <img :src="item.imgurl" alt="img" width="100%">
                            </div>
                            <div class="upload-box flex-center" v-else>
                                <i class="el-icon-plus"></i>
                                <span class="text">点击上传</span>
                            </div>
                        </Upload>
                    </div>
                </Kingkong>
                <div class="submit">
                    <el-button type="primary" @click="onConfirm">确定</el-button>
                    <el-button @click="onCancel">取消</el-button>
                </div>
            </div>
            <div class="demo">
                <h3 class="title">金刚区示例</h3>
                <img src="https://image.topboom0912.com/admin_static_img/demo/index_kingkong.png" alt="demo"
                     width="298px">
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex'
    import PageTitle from '@/components/PageTitle'
    import Kingkong from '@/components/KingKong'
    import Upload from '@/components/Upload'

    export default {
        name: 'KingKong',
        components: {
            PageTitle,
            Kingkong,
            Upload
        },
        data() {
            return {
                edit: [],
                index: null
            }
        },
        computed: mapState([
            'categoryOptions'
        ]),
        watch: {
            categoryOptions: async function () {
                await this.initList()
            }
        },
        async mounted() {
            await this.initList()
        },
        methods: {
            async initList() {
                this.edit = await this.getList()
                if (this.edit.length === 0) {
                    for (let index = 0; index < 8; index++) {
                        this.edit.push({
                            activity_name: '',
                            jump_type: 4,
                            img: {
                                w: 100,
                                h: 100,
                                url: ''
                            },
                            imgurl: '',
                            external_params: '',
                            position_type: 2
                        })
                    }
                }
            },
            getList() {
                const params = {
                    page_size: 8,
                    page_no: 0,
                    position_type: 2
                }
                const url = 'admin/activity/list'
                return new Promise(resolve => {
                    this.$https.get(url, {params}).then(res => {
                        if (res.list) {
                            res.list.forEach(item => {
                                item.imgurl = this.$img(item.img)
                                item.img = JSON.parse(item.img)
                                if (item.external_params) {
                                    let grandsonItem = null
                                    const db = JSON.parse(JSON.stringify(this.categoryOptions))
                                    db.forEach(parent => {
                                        parent.children.forEach(child => {
                                            child.children.forEach(grandson => {
                                                grandson.checked = grandson.id === item.external_params * 1
                                                if (grandson.id === item.external_params * 1) grandsonItem = grandson
                                            })
                                        })
                                    })
                                    let childItem = null
                                    db.forEach(parent => {
                                        parent.children.forEach(child => {
                                            if (grandsonItem) {
                                                child.checked = child.id === grandsonItem.pid
                                                if (child.id === grandsonItem.pid) childItem = child
                                            }
                                        })
                                    })
                                    // db.forEach(parent => {
                                    //   if (childItem) {
                                    //     parent.checked = parent.id === childItem.pid
                                    //     if (parent.id === childItem.pid) childItem = parent
                                    //   }
                                    // })
                                    if (childItem && grandsonItem) item.external_params = [childItem.pid, grandsonItem.pid, grandsonItem.id]
                                }
                            })
                            resolve(res.list)
                        } else {
                            resolve([])
                        }
                    })
                })
            },
            chooseIndex(index) {
                this.index = index
            },
            onUpload(url) {
                this.edit[this.index].imgurl = url
            },
            async onConfirm() {
                const res = []
                this.edit.forEach(async (item, index) => {
                    if (typeof item.img !== 'string') {
                        if (item.imgurl !== item.img.url) item.img.url = item.imgurl
                        item.img = JSON.stringify(item.img)
                    }
                    delete item.imgurl
                    item.external_params = Array.isArray(item.external_params) ? item.external_params[2] : ''
                    const params = Object.assign({}, item, {sort: index + 1})
                    const url = 'admin/activity/edit'
                    const data = await this.$https.post(url, params)
                    res.push(data)
                })
                Promise.all(res).then(() => {
                    this.$message.success('保存成功')
                })
            },
            onCancel() {
            }
        }
    }
</script>

<style scoped lang="scss">
    .edit {
        padding: 0 32px;
        border-right: 1px solid #E8E8E8;
        box-sizing: border-box;

        .tips {
            padding-top: 8px;
            margin-bottom: 32px;
            color: rgba(0, 0, 0, 0.45)
        }

        .submit {
            padding: 32px 0 8px;
            text-align: center;
        }

        .upload {
            display: flex;
            flex-direction: column;
            line-height: 1;

            &-box {
                width: 104px;
                height: 104px;
                flex-direction: column;
                border-radius: 4px;
                border: 1px dashed rgba(0, 0, 0, 0.15);
                cursor: pointer;

                .text {
                    padding-top: 8px;
                    color: rgba(0, 0, 0, 0.65);
                }
            }

            .logo {
                position: relative;
                width: 104px;
                height: 104px;
                overflow: hidden;
                cursor: pointer;
            }
        }
    }

    .demo {
        padding-left: 70px;
    }

    .title {
        margin: 0;
        padding-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        color: rgba(0, 0, 0, 0.85);
    }
</style>
